import Box, {BoxProps} from '@mui/material/Box';
import Link from '@mui/material/Link';
import {useTheme} from '@mui/material/styles';
import {forwardRef} from 'react';
import {RouterLink} from 'src/routes/components';

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({disabledLink = false, sx, ...other}, ref) => {
    const theme = useTheme();
    const PRIMARY_LIGHT = theme.palette.primary.light;
    const PRIMARY_MAIN = theme.palette.primary.main;
    const PRIMARY_DARK = theme.palette.primary.dark;

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 32,
          height: 32,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="277.53 105.65 444.94 442.1">
          <defs>
            <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
              <stop offset="0%" stopColor={PRIMARY_DARK}/>
              <stop offset="100%" stopColor={PRIMARY_MAIN}/>
            </linearGradient>

            <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={PRIMARY_LIGHT}/>
              <stop offset="100%" stopColor={PRIMARY_MAIN}/>
            </linearGradient>

            <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={PRIMARY_LIGHT}/>
              <stop offset="100%" stopColor={PRIMARY_MAIN}/>
            </linearGradient>
          </defs>

          <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
            <path
              d="M444.55,310l48.53-28a13.83,13.83,0,0,1,13.83,0l48.53,28a13.82,13.82,0,0,1,6.35,8.07l35.15-20.29A24.2,24.2,0,0,0,609,276.83v-98a24.19,24.19,0,0,0-12.09-20.94l-84.85-49a24.18,24.18,0,0,0-24.18,0l-84.84,49A24.19,24.19,0,0,0,391,178.86v98a24.2,24.2,0,0,0,12.09,20.95l35.14,20.28A13.85,13.85,0,0,1,444.55,310Z"/>
            <path
              d="M440.14,398.83a13.84,13.84,0,0,1-6.92-12v-56a13.8,13.8,0,0,1,.57-3.91l-35.14-20.29a24.18,24.18,0,0,0-24.18,0l-84.85,49a24.18,24.18,0,0,0-12.09,20.94v98a24.18,24.18,0,0,0,12.09,20.94l84.85,49a24.18,24.18,0,0,0,24.18,0l84.84-49a24.18,24.18,0,0,0,12.09-20.94V428.7a13.85,13.85,0,0,1-6.91-1.85Z"/>
            <path
              d="M722.47,376.53a24.18,24.18,0,0,0-12.09-20.94l-84.85-49a24.18,24.18,0,0,0-24.18,0L566.2,326.9a13.8,13.8,0,0,1,.57,3.91v56a13.83,13.83,0,0,1-6.91,12l-48.53,28a13.88,13.88,0,0,1-6.91,1.85v45.8a24.18,24.18,0,0,0,12.09,20.94l84.84,49a24.18,24.18,0,0,0,24.18,0l84.85-49a24.18,24.18,0,0,0,12.09-20.94Z"/>
          </g>
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{display: 'contents'}}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
