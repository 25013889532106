import {useMemo} from 'react';
import SvgColor from 'src/components/svg-color';
import {paths} from 'src/routes/paths';

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>
);

const ICONS = {
  dashboard: icon('dashboard'),
  workspace: icon('workspace'),
  gallery: icon('gallery'),
  import: icon('import'),
  export: icon('export'),
  miner: icon('miner'),
  dataset: icon('dataset'),
  annotator: icon('annotator'),
  augmentation: icon('augmentation'),
  model: icon('model'),
  deployment: icon('executable'),
  experiment: icon('experiment'),
  transfer: icon('transfer'),
  operations: icon('data-operations'),
  datastatistics: icon('data-statistics'),
  user: icon('user'),
  docs: icon('docs'),
  message: icon('message'),
};

export function useNavData() {
  return useMemo(
    () => [
      {
        subheader: 'BeeYard',
        items: [
          {title: 'Dashboard', path: paths.general.dashboard, icon: ICONS.dashboard},
        ],
      },
      {
        subheader: 'Data Storage',
        items: [
          {
            title: 'Browser',
            path: paths.dataStorage.browser,
            icon: ICONS.gallery
          },
          {
            title: 'Data transfer',
            path: paths.dataStorage.transfer,
            icon: ICONS.transfer,
            children: [
              {
                title: 'Import',
                path: paths.dataStorage.transferImport,
              },
              {
                title: 'Export',
                path: paths.dataStorage.transferExport,
              },
            ]
          },
          {
            title: 'Data operations',
            path: paths.dataStorage.ops,
            icon: ICONS.operations,
            children: [
              {
                title: 'Retention Policy',
                path: paths.dataStorage.opsRetention,
              },
              {
                title: 'Batch Modification',
                path: paths.dataStorage.opsBatchModifications,
              },
            ]
          },
          {
            title: 'Data Miner',
            path: paths.dataStorage.dataMiner,
            icon: ICONS.miner
          },
          {
            title: 'Statistics',
            path: paths.dataStorage.statistics,
            icon: ICONS.datastatistics
          },
        ],
      },
      {
        subheader: 'Dataset Management',
        items: [
          {
            title: 'Datasets',
            path: paths.datasetManagement.datasets,
            icon: ICONS.dataset
          },
          {
            title: 'Augmentation',
            path: paths.datasetManagement.augmentation,
            icon: ICONS.augmentation
          },
          {
            title: 'Annotators',
            path: paths.datasetManagement.annotators,
            icon: ICONS.annotator
          },
        ],
      },
      {
        subheader: 'Model Management',
        items: [
          {
            title: 'Models',
            path: paths.modelManagement.models,
            icon: ICONS.model
          },
          {
            title: 'Deployments',
            path: paths.modelManagement.deployments,
            icon: ICONS.deployment,
            children: [
              {
                title: 'Edge',
                path: paths.modelManagement.deploymentsEdge,
              },
              {
                title: 'Cloud',
                path: paths.modelManagement.deploymentsCloud,
              },
            ]
          },
        ],
      },
      {
        subheader: 'Experiment Management',
        items: [
          {
            title: 'Experiments',
            path: paths.experimentManagement.experiments,
            icon: ICONS.experiment
          },
        ],
      },
      {
        subheader: 'Settings',
        items: [
          {
            title: 'Users',
            path: paths.settings.users,
            icon: ICONS.user
          },
        ],
      },
      {
        subheader: 'Support',
        items: [
          {
            title: 'Docs',
            path: paths.support.docs,
            icon: ICONS.docs
          },
          {
            title: 'Message',
            path: paths.support.message,
            icon: ICONS.message
          },
        ],
      },
    ],
    []
  );
}
