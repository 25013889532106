import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {AuthGuard} from 'src/auth/guard';
import {LoadingScreen} from 'src/components/loading-screen';
import DashboardLayout from "../layouts/main";

const ModelListPage = lazy(() => import('src/model-management/models/list/model-list-page'));
const CloudDeploymentPage = lazy(() => import('src/model-management/deployments/cloud/cloud-deployment-page'));
const EdgeDeploymentPage = lazy(() => import('src/model-management/deployments/edge/edge-deployment-page'));

export const modelManagementRoutes = [
  {
    path: 'mm',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen/>}>
            <Outlet/>
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {element: <ModelListPage/>, index: true},
      {
        path: 'models', children: [
          {element: <ModelListPage/>, index: true}
        ]
      },
      {
        path: 'deployments', children: [
          {element: <CloudDeploymentPage/>, index: true},
          {path: 'cloud', element: <CloudDeploymentPage/>},
          {path: 'edge', element: <EdgeDeploymentPage/>}
        ]
      }
    ]
  }
];
