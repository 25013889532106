import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {AuthGuard} from 'src/auth/guard';
import {LoadingScreen} from 'src/components/loading-screen';
import DashboardLayout from "../layouts/main";

const DashboardPage = lazy(() => import('src/dashboard/dashboard-page'));

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen/>}>
            <Outlet/>
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {element: <DashboardPage/>, index: true}
    ],
  }
];
