import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {AuthGuard} from 'src/auth/guard';
import {LoadingScreen} from 'src/components/loading-screen';
import DashboardLayout from "../layouts/main";

const DatasetListPage = lazy(() => import('src/dataset-management/dataset/list/dataset-list-page'));
const AnnotatorsPage = lazy(() => import('src/dataset-management/annotators/annotators-page'));
const AugmentationPage = lazy(() => import('src/dataset-management/augmentation/augmentation-page'));

export const datasetManagementRoutes = [
  {
    path: 'dm',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen/>}>
            <Outlet/>
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {element: <DatasetListPage/>, index: true},
      {
        path: 'datasets', children: [
          {element: <DatasetListPage/>, index: true}
        ]
      },
      {path: 'annotators', element: <AnnotatorsPage/>},
      {path: 'augmentation', element: <AugmentationPage/>}
    ],
  }
];
