import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {AuthGuard} from 'src/auth/guard';
import {LoadingScreen} from 'src/components/loading-screen';
import DashboardLayout from "../layouts/main";
import BatchModificationPage from "./batch-modification/batch-modification-page";
import ExportPage from "./export/export-page";
import ImportPage from "./import/import-page";
import MinerPage from "./miner/miner-page";
import RetentionPage from "./retention/retention-page";
import StatisticsPage from "./statistics/statistics-page";

const FolderBrowserPage = lazy(() => import('src/data-storage/browser/folder/folder-browser-page'));
const CellBrowserPage = lazy(() => import('src/data-storage/browser/cell/cell-browser-page'));
const CellDetailPage = lazy(() => import('src/data-storage/cell/cell-detail-page'));

export const dataStorageRoutes = [
  {
    path: 'ds',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen/>}>
            <Outlet/>
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {element: <FolderBrowserPage/>, index: true},
      {
        path: 'browser', children: [
          {element: <FolderBrowserPage/>, index: true},
          {path: ':folderId', element: <CellBrowserPage/>},
          {path: 'cells/:cellId', element: <CellDetailPage/>},
        ]
      },
      {
        path: 'transfer', children: [
          {element: <ImportPage/>, index: true},
          {path: 'import', element: <ImportPage/>},
          {path: 'export', element: <ExportPage/>},
        ]
      },
      {
        path: 'ops', children: [
          {element: <RetentionPage/>, index: true},
          {path: 'retention', element: <RetentionPage/>},
          {path: 'batch-modification', element: <BatchModificationPage/>}
        ]
      },
      {path: 'miner', element: <MinerPage/>},
      {path: 'statistics', element: <StatisticsPage/>},
    ],
  }
];
