import {enUS as enUSCore,} from '@mui/material/locale';
import {enUS as enUSDate,} from '@mui/x-date-pickers/locales';
import merge from 'lodash/merge';
import 'dayjs/locale/en';

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSCore),
    adapterLocale: "en",
    icon: 'flagpack:gb-nir',
  }
];

export const defaultLang = allLangs[0];

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
