import {Navigate, useRoutes} from 'react-router-dom';
import {PATH_AFTER_LOGIN} from 'src/config-global';
import {dashboardRoutes} from "../../dashboard/dashboard-routes";
import {dataStorageRoutes} from "../../data-storage/data-storage-routes";
import {datasetManagementRoutes} from "../../dataset-management/dataset-management-routes";
import {experimentManagementRoutes} from "../../experiment-management/experiment-management-routes";
import {modelManagementRoutes} from "../../model-management/model-management-routes";
import {settingsRoutes} from "../../settings/settings-routes";
import {supportRoutes} from "../../support/support-routes";
import {authRoutes} from './auth';
import {mainRoutes} from './main';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace/>,
    },

    ...authRoutes,
    ...dashboardRoutes,
    ...dataStorageRoutes,
    ...datasetManagementRoutes,
    ...modelManagementRoutes,
    ...experimentManagementRoutes,
    ...settingsRoutes,
    ...supportRoutes,
    ...mainRoutes,

    {path: '*', element: <Navigate to="/404" replace/>},
  ]);
}
