import Typography from "@mui/material/Typography";
import {Helmet} from 'react-helmet-async';

export default function MinerPage() {
  return (
    <>
      <Helmet>
        <title>Data miner</title>
      </Helmet>

      <Typography>Data miner wizard placeholder</Typography>
    </>
  );
}
