import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {AuthGuard} from 'src/auth/guard';
import {LoadingScreen} from 'src/components/loading-screen';
import DashboardLayout from "../layouts/main";

const UserListPage = lazy(() => import('src/settings/users/list/user-list-page'));

export const settingsRoutes = [
  {
    path: 'settings',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen/>}>
            <Outlet/>
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {element: <UserListPage/>, index: true},
      {
        path: 'users', children: [
          {element: <UserListPage/>, index: true}
        ]
      }
    ]
  }
];
