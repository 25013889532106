import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {AuthGuard} from 'src/auth/guard';
import {LoadingScreen} from 'src/components/loading-screen';
import DashboardLayout from "../layouts/main";

const SupportRequestPage = lazy(() => import('src/support/request/support-request-page'));

export const supportRoutes = [
  {
    path: 'support',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen/>}>
            <Outlet/>
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {element: <SupportRequestPage/>, index: true},
      {
        path: 'request', children: [
          {element: <SupportRequestPage/>, index: true}
        ]
      }
    ]
  }
];
