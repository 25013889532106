import { useCallback, useEffect, useMemo, useReducer } from 'react';
import axios, { endpoints } from 'src/utils/axios';
import { AuthContext } from './auth-context';
import {
  areTokensValid,
  clearAuthentication,
  getAccessToken,
  getRefreshToken,
  jwtDecode,
  setupAuthentication,
} from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

type Props = {
  children: React.ReactNode;
};

let refreshTokenTimerId: number | undefined;

const refreshExpiringToken = (tokenExpiration: number, refresh: () => void) => {
  const currentTime = Date.now();
  const timeLeft = tokenExpiration * 1000 - currentTime - 10000;

  if (refreshTokenTimerId !== undefined) {
    clearTimeout(refreshTokenTimerId);
  }

  refreshTokenTimerId = window.setTimeout(() => {
    refresh();
  }, timeLeft);
};

const clearRefreshTokenTimer = () => {
  if (refreshTokenTimerId) {
    clearTimeout(refreshTokenTimerId);
  }
};

const processAuthenticationTokens = (
  accessToken: string,
  refreshToken: string,
  refresh: () => void
) => {
  setupAuthentication(accessToken, refreshToken);
  const { exp } = jwtDecode(accessToken);
  refreshExpiringToken(exp, refresh);
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const refresh = useCallback(async () => {
    const refreshToken = getRefreshToken();
    const payload: any = {
      user: null,
    };

    if (refreshToken) {
      const data = {
        grant_type: 'refresh_token',
        client_id: 'beeyardapp',
        refresh_token: refreshToken,
      };

      const headers = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Basic YmVleWFyZGFwcDo=',
        },
      };

      const response = await axios.post(endpoints.auth.login, data, headers);
      const { access_token: accessToken, refresh_token: newRefreshToken } = response.data;

      console.log('token successfully refreshed');

      if (areTokensValid(accessToken, newRefreshToken)) {
        processAuthenticationTokens(accessToken, newRefreshToken, refresh);
        const { username } = jwtDecode(accessToken);

        payload.user = {
          username,
        };
      } else {
        clearAuthentication();
        clearRefreshTokenTimer();
      }
    } else {
      console.log('no refresh token');
    }

    dispatch({
      type: Types.LOGIN,
      payload,
    });
  }, []);

  const login = useCallback(
    async (email: string, password: string) => {
      const data = {
        grant_type: 'password',
        client_id: 'beeyardapp',
        username: email,
        password,
      };

      const headers = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Basic YmVleWFyZGFwcDo=',
        },
      };

      const response = await axios.post(endpoints.auth.login, data, headers);
      const { access_token: accessToken, refresh_token: refreshToken } = response.data;

      const payload: any = {
        user: null,
        refreshTokenTimerId: null,
      };

      if (areTokensValid(accessToken, refreshToken)) {
        processAuthenticationTokens(accessToken, refreshToken, refresh);
        const { username } = jwtDecode(accessToken);

        payload.user = {
          username,
        };
        payload.refreshTokenTimerId = refreshTokenTimerId;
      } else {
        clearAuthentication();
        clearRefreshTokenTimer();
      }

      dispatch({
        type: Types.LOGIN,
        payload,
      });
    },
    [refresh]
  );

  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const response = await axios.post(endpoints.auth.register, data);
      const { user } = response.data;

      dispatch({
        type: Types.REGISTER,
        payload: {
          user,
        },
      });
    },
    []
  );

  const logout = useCallback(async () => {
    clearAuthentication();
    clearRefreshTokenTimer();
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  const initialize = useCallback(async () => {
    console.log('initializing');

    const action: any = {
      type: Types.INITIAL,
      payload: {
        user: null,
      },
    };

    try {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();

      if (areTokensValid(accessToken, refreshToken)) {
        processAuthenticationTokens(accessToken!, refreshToken!, refresh);
        const { username } = jwtDecode(accessToken!);

        action.payload.user = {
          user: username,
        };
      }
    } catch (error) {
      console.error(error);
    }

    dispatch(action);
  }, [refresh]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';
  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      login,
      refresh,
      register,
      logout,
    }),
    [login, refresh, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
