const ROOTS = {
  AUTH: '/auth',
  GENERAL: '/dashboard',
  DATA_STORAGE: '/ds',
  DATA_MANAGEMENT: '/dm',
  MODEL_MANAGEMENT: '/mm',
  EXPERIMENT_MANAGEMENT: '/em',
  SETTINGS: '/settings',
  SUPPORT: '/support'
};

export const paths = {
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  general: {
    dashboard: `${ROOTS.GENERAL}/`,
  },
  dataStorage: {
    browser: `${ROOTS.DATA_STORAGE}/browser`,
    transfer: `${ROOTS.DATA_STORAGE}/transfer`,
    transferImport: `${ROOTS.DATA_STORAGE}/transfer/import`,
    transferExport: `${ROOTS.DATA_STORAGE}/transfer/export`,
    ops: `${ROOTS.DATA_STORAGE}/ops`,
    opsRetention: `${ROOTS.DATA_STORAGE}/ops/retention`,
    opsBatchModifications: `${ROOTS.DATA_STORAGE}/ops/batch-modification`,
    dataMiner: `${ROOTS.DATA_STORAGE}/miner`,
    statistics: `${ROOTS.DATA_STORAGE}/statistics`,
  },
  datasetManagement: {
    datasets: `${ROOTS.DATA_MANAGEMENT}/datasets`,
    augmentation: `${ROOTS.DATA_MANAGEMENT}/augmentation`,
    annotators: `${ROOTS.DATA_MANAGEMENT}/annotators`,
  },
  modelManagement: {
    models: `${ROOTS.MODEL_MANAGEMENT}/models`,
    deployments: `${ROOTS.MODEL_MANAGEMENT}/deployments`,
    deploymentsEdge: `${ROOTS.MODEL_MANAGEMENT}/deployments/edge`,
    deploymentsCloud: `${ROOTS.MODEL_MANAGEMENT}/deployments/cloud`,
  },
  experimentManagement: {
    experiments: `${ROOTS.EXPERIMENT_MANAGEMENT}/experiments`
  },
  settings: {
    users: `${ROOTS.SETTINGS}/users`
  },
  support: {
    docs: `https://docs.beeyard.ai/docs/`,
    message: `${ROOTS.SUPPORT}/request`,
  }
};
