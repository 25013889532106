import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'simplebar-react/dist/simplebar.min.css';
import {AuthConsumer, AuthProvider} from 'src/auth/context/jwt';
import {MotionLazy} from 'src/components/animate/motion-lazy';
import ProgressBar from 'src/components/progress-bar';
import {SettingsDrawer, SettingsProvider} from 'src/components/settings';
import {useScrollToTop} from 'src/hooks/use-scroll-to-top';
import Router from 'src/routes/sections';
import ThemeProvider from 'src/theme';
import {SnackbarProvider} from "./components/snackbar";
import {LocalizationProvider} from "./locales";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('UTC');

export default function App() {
  useScrollToTop();

  return (
    <AuthProvider>
      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'dark',
            themeDirection: 'ltr',
            themeContrast: 'default',
            themeLayout: 'vertical',
            themeColorPresets: 'orange',
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <SettingsDrawer/>
                <ProgressBar/>
                <AuthConsumer>
                  <Router/>
                </AuthConsumer>
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}
