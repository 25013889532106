import axios from 'src/utils/axios';

export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';

export const jwtDecode = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export const getAccessToken = () => sessionStorage.getItem(ACCESS_TOKEN_KEY);

export const getRefreshToken = () => sessionStorage.getItem(REFRESH_TOKEN_KEY);

export const areTokensValid = (accessToken: string | null, refreshToken: string | null) => {
  if (!accessToken || !refreshToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const clearAuthentication = () => {
  sessionStorage.removeItem(ACCESS_TOKEN_KEY);
  sessionStorage.removeItem(REFRESH_TOKEN_KEY);

  delete axios.defaults.headers.common.Authorization;
}

export const setupAuthentication = (accessToken: string, refreshToken: string) => {
  sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}
