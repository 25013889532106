import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {alpha, useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Logo from 'src/components/logo';
import {useResponsive} from 'src/hooks/use-responsive';
import {bgGradient} from 'src/theme/css';

type Props = {
  children: React.ReactNode;
};

export default function AuthClassicLayout({children}: Props) {
  const theme = useTheme();
  const upMd = useResponsive('up', 'lg');

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: {xs: 2, md: 5},
      }}
    />
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: {xs: 2, md: 8},
        py: {xs: 15, md: 30},
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      spacing={10}
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/images/overlay.jpg',
        }),
      }}
    >
      <Typography variant="h3" sx={{maxWidth: 720, textAlign: 'center'}}>
        Convert images into valuable data
      </Typography>

      <Box
        component="img"
        alt="auth"
        src="/assets/images/login-image.png"
        sx={{maxHeight: 480}}
      />

      <Typography variant="body1" sx={{maxWidth: 720, textAlign: 'center'}}>
        Revolutionize your business operations with our cutting-edge, image-centric AI/ML engineering data platform. Our
        flexible data management system effortlessly collects and contextualizes metadata alongside rich imagery data,
        unleashing powerful AI insights that drive business success.
      </Typography>
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      {renderLogo}

      {upMd && renderSection}

      {renderContent}
    </Stack>
  );
}
