import Typography from "@mui/material/Typography";
import {Helmet} from 'react-helmet-async';

export default function StatisticsPage() {
  return (
    <>
      <Helmet>
        <title>Storage statistics</title>
      </Helmet>

      <Typography>Data storage statistics placeholder</Typography>
    </>
  );
}
