import Typography from "@mui/material/Typography";
import {Helmet} from 'react-helmet-async';

export default function ExportPage() {
  return (
    <>
      <Helmet>
        <title>Export data</title>
      </Helmet>

      <Typography>Export wizard placeholder</Typography>
    </>
  );
}
