import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {AuthGuard} from 'src/auth/guard';
import {LoadingScreen} from 'src/components/loading-screen';
import DashboardLayout from "../layouts/main";

const ExperimentListPage = lazy(() => import('src/experiment-management/experiment/list/experiment-list-page'));

export const experimentManagementRoutes = [
  {
    path: 'em',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen/>}>
            <Outlet/>
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {element: <ExperimentListPage/>, index: true},
      {path: 'experiments', element: <ExperimentListPage/>},
    ]
  }
];
